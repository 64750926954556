<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ agency.title }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
        <div class="head-card-bottom">
          <b-button
            v-b-tooltip.hover.top="$t('Settings')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            :to="{ path: `/users/${$route.params.userId}/agencies/${$route.params.id}/edit`}"
          >
            <feather-icon
              icon="SettingsIcon"
              size="16"
            />
            {{ $t('Settings') }}
          </b-button>
        </div>
      </div>
    </b-card>
    <agency-users-table :key="$route.fullPath" />
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import {
  BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import AgencyUsersTable from './AgencyUsersTable.vue'

export default {
  components: {
    BCard,
    Breadcrumbs,
    BButton,
    AgencyUsersTable,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageTitle: '',
      pageSubtitle: '',
      account: {},
      authUserData: getUserData(),
      agency: [],
      breadcrumbItems: [],
      status: {},
      all_pages: [],
      ...options,
    }
  },
  created() {
    this.getAgencyData()
  },
  methods: {
    async getAgencyData() {
      const responseData = await useJwt.getAgency(this.$route.params.id)
      this.agency = responseData.data.agency || []
      this.getUserData()
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('Users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.agency.owner}`,
        },
        {
          text: this.$t('Partners'),
          to: `/users/${this.agency.owner}/agencies`,
        },
        {
          text: this.agency.title,
          active: true,
        },
      ]
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
