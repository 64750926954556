<template>
  <b-card>
    <div
      v-if="accountsLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <div
        v-if="!accounts.length"
        class="d-flex justify-content-center mb-1"
      >
        <div
          class="text-center"
        >
          <h5>У Вас нет ни одного пользователей</h5>
          <p>Для добавление нового пользователей воспользуйтесь кнопкой:</p>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            :to="{ path: `/users/${$route.params.userId}/agencies/${$route.params.id}/create`}"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            {{ $t('profile.useradd') }}
          </b-button>
        </div>
      </div>
      <div
        v-else
        class="widgets-table"
      >
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between align-items-center mb-1">
          <div>
            <strong>{{ $t('profile.usertotal') }}: {{ accounts.length }}</strong>
          </div>
          <div class="d-flex">
            <b-form-group class="mb-0 mr-1">
              <div class="d-flex align-items-center">
                <label class="mr-1 mb-0">{{ $t('message.seachLabel') }}</label>
                <b-input-group>
                  <b-form-input
                    ref="searchInput"
                    :value="searchTerm"
                    :placeholder=" $t('form.search')"
                    type="text"
                    class="d-inline-block"
                    @keyup.enter="searchTerm = $event.target.value"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="searchTerm = $refs.searchInput.vModelValue"
                    >
                      <feather-icon
                        icon="SearchIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-form-group>
            <b-button-toolbar justify>
              <b-button-group>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  :to="{ path: `/users/${$route.params.userId}/agencies/${$route.params.id}/create`}"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                  />
                  {{ $t('profile.useradd') }}
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="accounts"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Status'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.status') }}
            </span>
            <span
              v-else-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.name') }}
            </span>
            <span
              v-else-if="props.column.label ==='Action'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.action') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'name'"
              class="text-nowrap"
            >
              <span>
                <router-link :to="'/users/'+ props.row._id">
                  {{ props.row.name }}
                </router-link>
                <b-badge
                  v-if="props.row.owns.sites"
                  title="Publisher"
                >
                  P
                </b-badge>
                <b-badge
                  v-if="props.row.owns.campaigns"
                  variant="warning"
                  title="Advertiser"
                >
                  A
                </b-badge>
              </span>
            </span>

            <!-- Column: Balance -->
            <span
              v-if="props.column.field === 'balance'"
              class="text-nowrap"
            >
              <span>
                {{ currency(props.row.currency, props.row.balance) }}
              </span>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  right
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    title="Agency member settings"
                    :to="'/users/'+ $route.params.userId +'/agencies/'+ $route.params.id +'/'+ props.row._id + '/edit/'"
                  >
                    <feather-icon
                      icon="PieChartIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('partners.settings') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="'/users/'+ props.row._id +'/edit/'"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.edit') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    class="text-danger"
                    @click="removeAgencyMember(props.row._id)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>

  </b-card>
</template>

<script>
import currencyFormatter from '@/utils/currency-formatter'
import useJwt from '@/auth/jwt/useJwt'
import {
  BSpinner,
  BButton,
  BCard,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BButtonToolbar,
  BButtonGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BSpinner,
    BCard,
    BBadge,
    BButton,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButtonToolbar,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      accountsLoading: true,
      currency: currencyFormatter,
      accounts: [],
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Balance',
          field: 'balance',
          width: '33%',
        },
        {
          label: 'Action',
          width: '5%',
          field: 'action',
          sortable: false,
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getAgencyMembers()
  },
  methods: {
    async getAgencyMembers() {
      const responseData = await useJwt.getAgencyMembers({ agency_id: this.$route.params.id })
      this.accounts = responseData.data.accounts || []
      this.accountsLoading = false
    },
    removeAgencyMember(id) {
      const memberIdx = this.accounts.findIndex(m => m._id === id)
      const member = this.accounts[memberIdx]

      if (member.length !== 0) {
        this.$swal({
          title: `Delete ${member.name}`,
          text: 'Do you really want to delete this agency member?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('swal.memberdeleted'),
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            await useJwt.removeAgencyMember(this.$route.params.id, { user_id: id })
            await useJwt.setUserRoles(id, { roles: ['Dummy'] })
            this.getAgencyMembers()
          }
        })
      }
    },
  },
}
</script>
<style scoped>
.badge + .badge {
  margin-left: 5px;
}
</style>
